// common.scss
* {
  font-family: var(--bs-body-font-family);
}
html {
  font-size: 16px; // rem 기준
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--bs-dark);
  /* Prevent font scaling in landscape */
  -webkit-text-size-adjust: none; /*Chrome, Safari, newer versions of Opera*/
  -moz-text-size-adjust: none; /*Firefox*/
  -ms-text-size-adjust: none; /*Ie*/
  -o-text-size-adjust: none; /*old versions of Opera*/
  min-width: 1280px;
}
a,
a:hover {
  text-decoration: none;
  color: var(--bs-dark);
}
ul,
ol {
  padding-left: 0;
  margin: 0;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}
li {
  list-style: none;
}
button {
  border: 0;
  outline: 0;
  background: transparent;
}
img {
  max-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.5;
}
p,
figure,
dl,
dd {
  margin: 0;
}
::selection {
  background: rgb(205, 223, 243);
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px 8px var(--bs-ts);
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  box-shadow: inset 0 0 8px 8px var(--bs-gray-500);
  border: solid 2px transparent;
}
::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 8px 8px var(--bs-gray-600);
}

// ----------------------------------------------------------------------------
// --------------------------------- bootstrap --------------------------------
// ----------------------------------------------------------------------------
// container
.container-fluid {
  max-width: 100%;
  padding: 0;
}
.container {
  position: relative;
  width: 100%;
  max-width: 100%;
  min-width: 1080px;
  padding: 0 2rem;
  margin: 0 auto;
  &.container-m15 {
    min-width: 1500px;
  }
  &.container-110 {
    min-width: 1100px;
  }
  &.container-135 {
    min-width: 1350px;
  }
  &.container-128 {
    min-width: 1280px;
  }
}
.container-xs {
  max-width: 400px;
  padding: 0;
}
.container-sm {
  max-width: 600px;
  padding: 0;
}
.container-md {
  max-width: 1024px;
  padding: 0;
}
.container-xl {
  max-width: 1420px;
  padding: 0;
}
// row
.row > * {
  min-width: 1px;
}

// btn
.btn {
  flex-shrink: 0;
  font-weight: 500;
  font-size: var(--fs-16);
  padding: 0.6rem 0.8rem;
  border-radius: 0.25rem;
  line-height: 1.5;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-shadow-rgb), 0.2);
  --bs-btn-active-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-shadow-rgb), 0.5);
  // state
  &:focus {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    box-shadow: var(--bs-btn-focus-box-shadow);
    outline: 0;
  }
  &:active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
    box-shadow: var(--bs-btn-active-box-shadow);
    outline: 0;
  }
  &:disabled {
    pointer-events: none;
    opacity: 1;
    color: var(--bs-btn-disabled-color);
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
  }
  // size
  &.btn-sm {
    padding: 0.25rem 0.5rem;
  }
  &.btn-lg {
    font-size: var(--fs-16);
    padding: 1rem 1.5rem;
  }
  // variant
  &.btn-underline {
    padding: 0;
    border: 0;
    width: auto;
    text-align: left;
    text-decoration: underline;
    color: var(--bs-primary) !important;
    font-weight: 500;
    &:focus,
    &:active {
      box-shadow: none !important;
      outline: 0 !important;
    }
  }
  &.btn-text-primary {
    padding: 0;
    border: 0;
    width: auto;
    text-align: left;
    color: var(--bs-primary) !important;
    font-weight: 500;
    &:focus,
    &:active {
      box-shadow: none !important;
      outline: none !important;
    }
  }
  &.btn-text-dark {
    padding: 0;
    border: 0;
    width: auto;
    text-align: left;
    color: var(--bs-dark) !important;
    font-weight: 500;
    &:focus,
    &:active {
      box-shadow: none !important;
      outline: none !important;
    }
  }
  &.btn-icon {
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 0;
    i {
      transition: 0.2s ease-in-out;
    }
  }
  &.btn-icon-text {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 0;
    i {
      transition: 0.2s ease-in-out;
      & + * {
        margin-left: 0.5rem;
      }
    }
  }
  &.btn-primary {
    --bs-btn-bg: var(--bs-primary);
    --bs-btn-border-color: var(--bs-primary);
    --bs-btn-color: var(--bs-white);
    --bs-btn-hover-bg: var(--bs-primary-dark);
    --bs-btn-hover-border-color: var(--bs-primary-dark);
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-focus-bg: var(--bs-primary-dark);
    --bs-btn-focus-border-color: var(--bs-primary-dark);
    --bs-btn-focus-color: var(--bs-white);
    --bs-btn-active-bg: var(--bs-primary-dark);
    --bs-btn-active-border-color: var(--bs-primary-dark);
    --bs-btn-active-color: var(--bs-white);
    --bs-btn-disabled-bg: var(--bs-gray-400);
    --bs-btn-disabled-border-color: var(--bs-gray-400);
    --bs-btn-disabled-color: var(--bs-gray-600);
    --bs-btn-shadow-rgb: var(--bs-primary-rgb);
  }
  &.btn-outline-primary {
    --bs-btn-bg: var(--bs-ts);
    --bs-btn-border-color: var(--bs-primary);
    --bs-btn-color: var(--bs-dark);
    --bs-btn-hover-bg: rgba(var(--bs-primary-rgb), 0.08);
    --bs-btn-hover-border-color: var(--bs-primary);
    --bs-btn-hover-color: var(--bs-dark);
    --bs-btn-focus-bg: rgba(var(--bs-primary-rgb), 0.12);
    --bs-btn-focus-border-color: var(--bs-primary);
    --bs-btn-focus-color: var(--bs-dark);
    --bs-btn-active-bg: rgba(var(--bs-primary-rgb), 0.12);
    --bs-btn-active-border-color: var(--bs-primary);
    --bs-btn-active-color: var(--bs-dark);
    --bs-btn-disabled-bg: var(--bs-ts);
    --bs-btn-disabled-border-color: var(--bs-gray-400);
    --bs-btn-disabled-color: var(--bs-gray-600);
    --bs-btn-shadow-rgb: var(--bs-primary-rgb);
  }
  &.btn-ts {
    --bs-btn-bg: var(--bs-ts);
    --bs-btn-border-color: var(--bs-ts);
    --bs-btn-color: var(--bs-dark);
    --bs-btn-hover-bg: rgba(var(--bs-primary-rgb), 0.08);
    --bs-btn-hover-border-color: var(--bs-ts);
    --bs-btn-hover-color: var(--bs-dark);
    --bs-btn-focus-bg: rgba(var(--bs-primary-rgb), 0.12);
    --bs-btn-focus-border-color: var(--bs-ts);
    --bs-btn-focus-color: var(--bs-dark);
    --bs-btn-active-bg: rgba(var(--bs-primary-rgb), 0.12);
    --bs-btn-active-border-color: var(--bs-ts);
    --bs-btn-active-color: var(--bs-dark);
    --bs-btn-disabled-bg: var(--bs-ts);
    --bs-btn-disabled-border-color: var(--bs-ts);
    --bs-btn-disabled-color: var(--bs-gray-600);
    --bs-btn-shadow-rgb: var(--bs-gray-rgb);
  }
}

// form

.form-control:-webkit-autofill {
  /* 자동완성이 발생할 때의 스타일을 지정합니다. */
  background: red !important;
  color: red;
}
.form-control {
  padding: 0.6rem 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  border-color: var(--bs-border);
  font-weight: 500;
  color: var(--bs-dark);
  &::placeholder {
    color: var(--bs-secondary);
    font-weight: 400;
  }
  &:focus {
    border-color: var(--bs-primary);
    box-shadow: 0px 0px 0px 4px rgba(var(--bs-primary-rgb), 0.2);
  }
  &:disabled {
    background: var(--bs-border);
  }
  &[readonly] {
    padding: 0.65rem 0;
    background-color: transparent;
    border-color: transparent;
    cursor: inherit;
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
  &.mw-280 {
    max-width: 280px;
  }
  &.w-100 {
    width: 100%;
  }
  &.max-100 {
    max-width: 100%;
  }
  &.mw-200 {
    max-width: 200px;
  }
}
.form-select {
  border-color: vae(--bs-border);
  background-color: transparent;
  background-image: url('../images/icon/dropdown.png');
  background-position: calc(100% - 8px) center;
  background-size: 8px auto;
  &:focus,
  &:active {
    background-color: transparent;
  }
}
small,
.form-text {
  color: var(--bs-gray);
  font-size: var(--fs-12);
  display: block;
  word-break: keep-all;
  margin-top: 0.5rem;
}
.form-check:not(.form-switch) {
  padding-left: 0;
  .form-check-input {
    width: 0;
    opacity: 0;
    &:checked {
      & ~ label {
        &:after {
          opacity: 1;
        }
      }
    }
    &:focus {
      & ~ label {
        &:before {
          outline-color: rgba(var(--bs-primary-rgb), 0.3);
          outline-style: solid;
          outline-width: 0;
        }
      }
    }
    &[disabled],
    &:disabled {
      opacity: 0;
      & ~ label {
        opacity: 0.4;
        &:before,
        &:after {
          background-image: url('../images/icon/checkbox_disabled.png');
        }
      }
    }
    &[type='radio'] {
      & + label {
        &:before,
        &:after {
          border-radius: 100%;
        }
        &:before {
          background-image: url('../images/icon/radio_unchecked.png');
        }
        &:after {
          background-image: url('../images/icon/radio_checked.png');
        }
      }
      &:checked {
        & + label {
          &:before {
            border-color: var(--bs-dark);
          }
          &:after {
            transform: scale(1);
          }
        }
      }
      &[disabled],
      &:disabled {
        & ~ label {
          &:before,
          &:after {
            background-image: url('../images/icon/radio_disabled.png');
          }
        }
      }
    }
  }
  &.only-input {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    label {
      width: 0;
      height: 24px;
      padding-left: 0;
    }
  }
  label {
    font-weight: 400;
    padding-left: 2rem;
    position: relative;
    font-size: var(--fs-16);
    cursor: pointer;
    &:before {
      content: '';
      display: block;
      border-radius: 0.25rem;
      width: 24px;
      height: 24px;
      background-image: url('../images/icon/checkbox_unchecked.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px auto;
      position: absolute;
      top: calc(50% - 12px);
      left: 0;
      transition: 0.2s ease-in-out;
    }
    &:after {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background-image: url('../images/icon/checkbox_checked.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px auto;
      position: absolute;
      top: calc(50% - 12px);
      left: 0;
      opacity: 0;
      transform-origin: center;
      transition: 0.2s ease-in-out;
    }
  }
}
.form-check {
  margin: 0;
  line-height: 40px;
}
.flex-start {
  .form-check {
    margin-right: 1.5rem;
  }
}
.form-label {
  color: var(--bs-dark);
  font-weight: 400;
  &.required {
    &:after {
      content: ' *';
      color: var(--bs-danger);
    }
  }
}
.form-switch {
  padding-left: 2.5rem;
  .form-check-input {
    border: 0;
    width: 2.25rem;
    height: 1.4rem;
    margin-left: -2.25rem;
    background-color: #dde6ff;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
    background-position: left center;
    border-radius: 2.25rem;
    transition: 0.2s ease-in-out;
    &:checked {
      background-color: var(--bs-info);
      border-color: var(--bs-dark);
    }
  }
}
.input-switch {
  &.style-switch-two {
    input {
      &[type='checkbox'] {
        & + label {
          &:after {
            width: 12px;
            height: 12px;
          }
        }
        &:checked {
          & + label {
            background-color: var(--bs-primary);
            &:before {
              background-color: var(--bs-primary);
            }
          }
        }
      }
    }
  }
}
.input-group {
  position: relative;
  &:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
  &:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
    border-radius: 0.25rem;
  }
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
      .valid-feedback
    ):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0.5rem;
    border-radius: 0.25rem;
    padding: 0;
    min-width: 120px;
    &.form-control {
      padding: 0.6rem 0.8rem;
    }
  }
  .input-group-text {
    border: 0;
    padding: 0;
    background-color: transparent;
    color: var(--bs-gray);
  }
  .timer {
    font-size: var(--fs-14);
  }
}
// valid, invalid
.was-validated .form-control:valid,
.form-control.is-valid {
  padding-right: 2.5rem;
  border-color: var(--bs-success);
  background-image: url('../images/icon/input_valid.png');
  background-size: 24px 24px;
  background-position: right 0.65rem center;
  background-repeat: no-repeat;
  &:focus {
    border-color: var(--bs-success);
    box-shadow: 0px 0px 0px 4px rgba(49, 208, 170, 0.2);
  }
}
.valid-feedback {
  color: var(--bs-success);
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  padding-right: 2.5rem;
  border-color: var(--bs-danger);
  background-image: url('../images/icon/input_invalid.png');
  background-size: 24px 24px;
  background-position: right 0.65rem center;
  background-repeat: no-repeat;
  &:focus {
    border-color: var(--bs-danger);
    box-shadow: 0px 0px 0px 4px rgba(223, 17, 37, 0.2);
  }
}
.invalid-feedback {
  color: var(--bs-danger);
}

// badge
.badge {
  padding: 0.4rem 0.4rem 0.3rem;
  font-size: var(--fs-11);
  font-weight: 500;
  &.md {
    min-width: 60px;
  }
  &.bg-primary {
    background: var(--bs-blue-bg-dark) !important;
    color: var(--bs-primary);
  }
  &.bg-primary-reverse {
    background: var(--bs-primary) !important;
    color: var(--bs-white);
  }
  &.bg-success {
    background: #e5f6ef !important;
    color: var(--bs-success);
  }
  &.bg-danger {
    background: #ffecee !important;
    color: var(--bs-danger);
  }
  &.bg-info {
    background: #326bff !important;
    color: var(--bs-white);
  }
  &.bg-gray {
    background: #959595 !important;
    color: var(--bs-white);
  }
}

// nav
.nav {
  &.type-btn {
    flex-wrap: nowrap;
    .nav-item {
      flex: 1;
      cursor: pointer;
      .nav-link {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--bs-gray);
        font-weight: 500;
        border-radius: 0.25rem;
        font-size: var(--fs-14);
        i {
          color: var(--bs-gray);
          margin-right: 0.5rem;
          position: relative;
        }
        &.active {
          background: var(--bs-primary);
          color: var(--bs-white);
          i {
            color: var(--bs-white);
          }
        }
      }
    }
  }
}

// nav-tab
.nav-tabs {
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  .nav-item {
    cursor: pointer;
    .nav-link {
      border: 0;
      position: relative;
      text-align: center;
    }
  }
  &.type-fill {
    display: inline-flex;
    padding: 0.25rem;
    background: var(--bs-gray-bg);
    border-radius: 8px;
    .nav-item {
      display: inline-flex;
      .nav-link {
        min-width: 140px;
        color: var(--bs-gray);
        padding: 0.35rem 0;
        border-radius: 8px;
        font-size: var(--fs-12);
        font-weight: 700;
        outline: none;
        &.active {
          border: 0;
          color: var(--bs-dark);
          background-color: var(--bs-white);
          box-shadow: 0px 0px 1px rgba(12, 23, 75, 0.1),
            0px 4px 20px rgba(25, 54, 128, 0.1);
        }
      }
    }
  }
  &.type-border {
    border-bottom: 1px solid var(--bs-dark);
    .nav-item {
      flex: 1;
      .nav-link {
        padding: 0.5rem 0;
        font-size: var(--fs-14);
        font-weight: 400;
        color: var(--bs-dark);
        &:after {
          content: '';
          display: none;
          width: 100%;
          height: 2px;
          background: var(--bs-dark);
          position: absolute;
          left: 0;
          bottom: 0;
        }
        &.active {
          font-weight: 700;
          &:after {
            display: block;
          }
        }
      }
    }
  }
}
.tab-content {
  width: 100%;
  & > .tab-pane {
    display: block;
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    &.active {
      opacity: 1;
      visibility: visible;
      height: inherit;
      overflow: inherit;
      padding: 0.5rem;
      margin: -0.5rem;
    }
  }
}

// card
.card {
  border-color: var(--bs-border);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  .card-header {
    border: 0;
    background: var(--bs-white);
    padding: 1.5rem;
  }
  .card-body {
    padding: 0 1.5rem 1.5rem;
  }
  .card-footer {
    border: 0;
    background: var(--bs-white);
    padding: 0 1.5rem 1.5rem;
    .btn-area {
      margin: 0;
      width: 100%;
      .btn-lg {
        flex-grow: 1;
      }
    }
  }
}

// accordion
.accordion {
  .accordion-item {
    background-color: transparent;
    border: 0;
    .accordion-button {
      border: 0;
      padding: 0;
      background-color: transparent;
      font-size: var(--fs-16);
      &:not(.collapsed) {
        background-color: transparent;
        color: var(--bs-dark);
        box-shadow: none;
        &:after {
          opacity: 1;
          transform: rotate(0);
        }
      }
      &:after {
        width: 24px;
        height: 24px;
        background-image: url('../images/icon/arrow_down_white.png');
        background-size: 24px 24px;
        background-position: center;
        opacity: 0.5;
        transform: rotate(-90deg);
      }
    }
    .accordion-body {
      background-color: transparent;
      padding: 0;
    }
  }
}

// table
.table {
  table-layout: fixed;
  --bs-table-hover-color: var(--bs-dark);
  --bs-table-hover-bg: var(--bs-white-dark);
  &.table-auto {
    table-layout: auto;
  }
  &.table-hover {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }
  &.text-start {
    th,
    td {
      // text-align: left;
      text-align: center;
    }
  }
  th,
  td {
    text-align: center;
    vertical-align: middle;
    color: var(--bs-dark);
  }
  th {
    background: rgba(var(--bs-primary-rgb), 0.1);
    font-size: var(--fs-12);
    font-weight: 500;
    padding: 0.25rem 0.5rem;
    border: 0;
  }
  td {
    padding: 0.75rem 0.5rem;
    font-size: var(--fs-16);
    font-weight: 500;
    .td-ellipsis {
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
      overflow: hidden;
      width: inherit;
      width: 100%;
    }
    .btn-area {
      margin: 0;
    }
    &.has-input,
    &.has-thumb {
      padding: 0.25rem 1rem;
    }
  }
  > :not(:first-child) {
    border-top: 0;
  }
  &.sm {
    td {
      padding: 0.5rem;
      font-size: var(--fs-14);
    }
  }
}

// pagination
.pagination {
  width: 100%;
  justify-content: center;
  .page-item {
    margin: 0 4px;
    .page-link {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      color: var(--bs-primary);
      font-size: var(--fs-12);
      font-weight: 800;
      border: 1px solid var(--bs-border);
      border-radius: 0.25rem;
      &.prev,
      &.next {
        background-repeat: no-repeat;
        background-position: center;
      }
      &.prev {
        background-image: url('../images/icon/page_prev.png');
      }
      &.next {
        background-image: url('../images/icon/page_next.png');
      }
      &.disabled {
        .page-link {
          &.prev {
            background-image: url('../images/icon/page_prev_disabled.png');
          }
          &.next {
            background-image: url('../images/icon/page_next_disabled.png');
          }
        }
      }
    }
    &.active {
      .page-link {
        background-color: var(--bs-primary);
        border: 1px solid var(--bs-primary);
        color: var(--bs-white);
      }
    }
  }
}

// dropdown
.dropdown {
  &.show {
    & > .dropdown-toggle {
      background-image: url('../images/icon/dropup.png');
    }
  }
  .dropdown-toggle {
    min-width: 185px;
    background-image: url('../images/icon/dropdown.png');
    background-repeat: no-repeat;
    background-position: right 1rem center;
    font-weight: 600;
    color: var(--bs-dark);
    &:after {
      display: none;
    }
    &:focus,
    &:active {
      box-shadow: 0px 0px 0px 4px rgba(25, 25, 25, 0.2);
    }
    &.btn-text,
    &.btn-icon {
      min-width: auto;
      background: transparent !important;
      color: var(--bs-dark) !important;
      outline: none !important;
    }
  }
  .dropdown-menu {
    min-width: 185px;
    margin-top: 0.4rem;
    padding: 0;
    border: 0;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    .dropdown-item-text,
    .dropdown-item {
      padding: 0.5rem 0.75rem;
      // border-radius: 8px;
      color: var(--bs-dark);
      font-weight: 400;
      border-bottom: 1px solid lightgray;
      &:focus {
        background-color: var(--bs-gray-300);
      }
      &:active {
        background-color: var(--bs-gray-300);
      }
      &.active {
        color: var(--bs-primary);
        font-weight: 700;
        background-color: transparent;
      }
    }
  }
}

.collapse:not(.show) {
  display: inherit;
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

// btn-group
.btn-group {
  &.type-btn {
    flex-wrap: wrap;
    input {
      & + .btn {
        margin: 0 0.5rem 0.5rem 0 !important;
        border: 0;
        background: var(--bs-blue-bg);
        border-radius: 100px !important;
        color: var(--bs-gray);
        font-weight: 500;
        flex: 0 0 auto;
      }

      &:checked {
        & + .btn {
          background: var(--bs-primary);
          color: var(--bs-white);
        }
      }
    }
  }
}
.btn-group-vertical {
  width: 100%;
  border-radius: 0.25rem;
  overflow: hidden;
  input {
    & + .btn {
      margin: 0 !important;
      padding: 1rem 0.75rem;
      justify-content: flex-start;
      background-color: var(--bs-white);
      color: var(--bs-dark);
      font-weight: 500;
      border: 0;
      border-bottom: 1px solid var(--bs-blue-bg-dark) !important;
      border-radius: 0;
      box-shadow: none !important;
      z-index: inherit !important;
    }
    &:checked {
      & + .btn {
        background-color: var(--bs-blue-bg-dark);
        border-color: var(--bs-blue-bg-dark);
        color: var(--bs-dark);
        font-weight: 700;
      }
    }
  }
}

// ratio
.ratio {
  flex-shrink: 0;
  overflow: hidden;
  &.ratio-2x1 {
    --bs-aspect-ratio: 50%;
  }
  &.ratio-2x3 {
    --bs-aspect-ratio: 150%;
  }
  &.ratio-7x2 {
    --bs-aspect-ratio: 28%;
  }
  &.ratio-35x19 {
    --bs-aspect-ratio: 54%;
  }
  &.ratio-70x93 {
    --bs-aspect-ratio: 132%;
  }
  &.ratio-7x10 {
    --bs-aspect-ratio: 142%;
  }
}

// toast
.toast-container {
  padding: 1.5rem;
  .toast {
    .toast-body {
      width: 100%;
      font-size: var(--fs-16);
      text-align: center;
    }
  }
}

// tooltip
.tooltip {
  --bs-tooltip-bg: var(--bs-dark);
  --bs-tooltip-color: var(--bs-white);
  --bs-tooltip-max-width: 300px;
  .tooltip-inner {
    background-color: var(--bs-tooltip-bg);
    color: var(--bs-tooltip-color);
    max-width: var(--bs-tooltip-max-width);
    text-align: left;
  }
}

.markerBox {
  z-index: 200;
  background-color: red;
}

// modal
.upper {
  z-index: 1055;
}

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// HOVER
@media (min-width: 1025px) {
  @media (hover: hover) {
    .btn {
      &:hover {
        color: var(--bs-btn-hover-color);
        background-color: var(--bs-btn-hover-bg);
        border-color: var(--bs-btn-hover-border-color);
      }
    }
    .dropdown {
      .dropdown-menu {
        .dropdown-item {
          &:hover {
            background: var(--bs-gray-100);
          }
        }
      }
    }
  }
}

.pie-height-pdf {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bar-height-pdf {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* 0 ~ 1340 */
@media screen and (max-width: 1440px) {
  html {
    font-size: 14px;
  }
}

/* 데이터 피커 disabled 스타일 */
// .react-datepicker__day--disabled {
//   background-color: #d9d2d2;
//   color: #999;
//   cursor: not-allowed;
//   &:hover  {
//     background-color: #d9d2d2;
//     border: 0;
//     color: #999;
//     cursor: not-allowed;
//   }
// }

.not-shadow {
  .chart-section {
    box-shadow: unset !important;
  }
}
